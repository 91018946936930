import React, { useState, useRef, useEffect } from "react";
import { products_api, tableHeaders } from "../../../../assets/config";
import { PopUp } from "../../Modal/popup";
import { UploadPopUp } from "../../Modal/UploadPopUp";
import Navbar from "../../Navbar";
import Details from "../Details/Details";
import axios from "axios";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { studentFields } from "../../../../assets/config";
import { grades_api,headers, program_api, exam_api, boards_api, } from "../../../../assets/config";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const formatDateTime = (dateTimeString) => {
  if (dateTimeString === "-") return "-";

  const date = new Date(dateTimeString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

const ExploreTable = () => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadPopup, setUploadPopUp] = useState(false);
  const [totalStudents, setTotalStudents] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [roleInfo, setRoleInfo] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("crn_id");
  const [studentData, setStudentData] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false); 
  const [filtertext,setFiltertext] = useState(""); 
  const [grades, setGrades] = useState([]);
  const [boards, setBoards] = useState([]);
  const [exams, setExams] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [products, setProducts] = useState([]);
  
  useEffect(() => {
  async function fetchGrades() {
    try {
      const response = await fetch(grades_api, {
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch grades');
      }
      const data = await response.json();
      //console.log("grades ", data);
      const grades = data.reduce((acc, grade) => {
        acc[grade.gradeId] = grade.name;
        //console.log("ACCUMILATOR"s, acc)
        return acc;
    }, {});

      setGrades(grades);
    } catch (error) { 
      console.error('Error fetching grades:', error.message);
    }
  }
  fetchGrades(); 
  //console.log('Grades fetched',grades);

  async function fetchPrograms() {
    try {
      const response = await fetch(program_api, {
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch grades');
      }
      const data = await response.json();
      //console.log("Programs ", data.programs)
      const programs = data.programs.reduce((acc, program) => {
        acc[program.program_id] = program.program_name;
        return acc;
    }, {});

      setPrograms(programs);
    } catch (error) { 
      console.error('Error fetching grades:', error.message);
    }
  }
  // fetchPrograms(); 
  //console.log('Programs fetched',programs);

  async function fetchBoards() {
    try {
      const response = await fetch(boards_api, {
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch grades');
      }
      const data = await response.json();
      //console.log("Boards ", data)
      const boards = data.reduce((acc, board) => {
        acc[board.board_id] = board.name;
        return acc;
      }, {});

      setBoards(boards);
    } catch (error) { 
      console.error('Error fetching grades:', error.message);
    }
  }
  // fetchBoards(); 
  //console.log('Boards fetched',boards);


  async function fetchExam() {
    try {
      const response = await fetch(exam_api, {
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch grades');
      }
      const data = await response.json();
      //console.log("Exams ",data)
      const exams = data.exams.reduce((acc, exam) => {
        acc[parseInt(exam.exam_id)] = exam.name;
        //console.log("ACCUMILATOR Exam", acc)
        return acc;
      }, {});

      setExams(exams);
    } catch (error) { 
      console.error('Error fetching grades:', error.message);
    }
  }
  fetchExam(); 
  //console.log('Exams fetched',exams);
  async function fetchProduct() {
    try {
      const response = await fetch(products_api, {
        headers: headers,
      });
      if (!response.ok) {
        throw new Error('Failed to fetch grades');
      }
      const data = await response.json();
      //console.log("Exams ",data)
      const products = data.products.reduce((acc, product) => {
        acc[product.product_id] = product.product_name;
        //console.log("ACCUMILATOR Exam", acc)
        return acc;
      }, {});

      setProducts(products)
  
    } catch (error) { 
      console.error('Error fetching grades:', error.message);
    }
  }
  fetchProduct(); 
  //console.log(' products fetched',products);
  }, []);


  const handleConfirmClose = (confirm) => {
    if (confirm) {
      setOpenDetails(false);
    }
    setShowConfirmation(false); 
  };

  const handleOpenDetails = (data) => {
    data["Name"] = data["first_name"] + " " + data["last_name"] ?? ""; 
    data["phone"] = data["phone"].length >= 6 ? 'XXXXXX'+ data["phone"].substring(6) : data["phone"];

    setStudentData(data);
    setOpenDetails(true);

    //console.log("crn", crn);
  };

  const handleCloseDetails = () => {
    if (showConfirmation) {
      setShowConfirmation(false);
    } else {
      setShowConfirmation(true);
    }
  };

  const handleNoButtonClick = () => {
    setOpenDetails(true);
    setShowConfirmation(false); 
 };
  const tableRef = useRef(null);

  const fetchRoleInfo = async () => {
    const roleUrl = `https://gatewayapi.infinitylearn.com/api/v1/role`;
    try {
      const response = await axios.get(roleUrl, {
        headers: {
          accept: "application/json",
          "product-id": "1000",
          "tenant-id": "1",
          "Content-Type": "application/json",
          platform: "web",
        },
      });
      if (response.status === 201) {
        
        setRoleInfo(response.data);
        return response.data;
      } else {
        console.error("Failed to fetch role information. Response:", response);
        throw new Error("Failed to fetch role information");
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    // fetchRoleInfo();
  }, []);

  const handleCloseModal = () => {
    setUploadPopUp(false);
  };

  const sortTable = (column) => {
    const sortedData = [...tableData].sort((a, b) => {
      if (column === "Grade") {
        return a.Grade - b.Grade;
      } else if (column === "Last Login") {
        return new Date(a["Last Login"]) - new Date(b["Last Login"]);
      } else if (column === "Registered On") {
        return new Date(a["Registered On"]) - new Date(b["Registered On"]);
      }
      return 0;
    });
  };

  const fetchData = () => {
    setIsLoading(true);
    var search_text = filtertext.split(",");
    var payload = {
      [selectedFilter]: search_text,
    };
    fetch(
      "https://gatewayapicms.infinitylearn.com/api/v1/auth/get-student-details",
      {
        method: "POST",
        headers: { ...headers,
          "Content-Type": "application/json",
          
        },
        body: JSON.stringify(payload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        //console.log("data...", data);
        const students_data = data.map((item) => item._source);
        const transformedData = students_data.map((student) => {
          //console.log("student ",student)
          const registeredOn = formatDateTime(student.created_at || "-");
          const lastLogin = formatDateTime(student.last_active_at || "-");
          return {
            Name:
              (
                <>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenDetails(student)}
                  >
                    {student.first_name}
                  </span>{" "}
                  {student.last_name != null && (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleOpenDetails(student)}
                    >
                      {student.last_name}
                    </span>
                  )}
                </>
              ) || "-",
            Grade: student.grade_id || "-",
            "Admission Number":
              (
                <>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenDetails(student)}
                  >
                    {student.admission_number}
                  </span>
                </>
              ) || "-",
            "reg source": student.registration_source || "-",
            "Registered On": registeredOn || "-",
            "Last Active At": lastLogin || "-",
          };
        });
        
        setTableData(transformedData);
        setTotalStudents(data.length);
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Navbar roleInfo={roleInfo} />
      {openDetails && (
        <Modal
          open={openDetails}
          // onClose={handleCloseDetails}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: "100%",
              height: "100%",
              backgroundColor: "#f5f5f9", 
              // background: 'rgb(2,0,36)',
              // backgroundColor: '#0056b3',
              color: "black"
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: 5, right: 5 }}
              onClick={handleCloseDetails}
            >
              <CloseIcon />
            </IconButton>
            <Details userDetails={studentData} grades={grades} programs={programs} exams={exams} boards={boards} products={products}/>
          </Box>
        </Modal>
      ) } 
        <div className="layout-wrapper">
          <div className="layout-container">
            <div className="content-wrapper">
              <div className="container">
                {/* <h4>Welcome to Insights Dashboard</h4> */}
                <span>
                  <select
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                    style={{ marginRight: "5px", width: "15%", 
                    padding: '7px',
                    fontSize: '14px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    outline: 'none',
                    transition: 'border-color 0.3s ease'  }}
                  >
                    <option value="admission_number">Admission Number</option>
                    <option value="phone">Phone</option>
                    <option value="crn_id">CRN ID</option>
                  </select>
                </span>
                <input
                  id="search_textid"
                  name="search_textn"
                  style={{ width: "30%", 
                  padding: '7px',
                  fontSize: '14px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  outline: 'none',
                  transition: 'border-color 0.3s ease' }} 
                  onChange={(e)=>setFiltertext(e.target.value)}
                />
                <button className="data-btn filter-btn m-1" onClick={fetchData}>
                  Get Data
                </button>
                {/* <button
                  className={`data-btn filter-btn m-1 ${
                    uploading ? "disabled" : ""
                  }`}
                  onClick={handleUpload}
                  disabled={uploading}
                >
                  {uploading ? "Uploading..." : "Upload Data"}
                </button> */}
                <div>Total Students: {totalStudents}</div>
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <div
                    className="table-responsive text-nowrap mt-3"
                    style={{ maxHeight: "80vh", overflowY: "auto" }}
                  >
                    <table className="table" ref={tableRef} id="table-to-xls">
                      <thead>
                        <tr className="text-nowrap">
                          {tableHeaders.map((header, index) => (
                            <th
                              key={index}
                              className="table-header"
                              onClick={() => sortTable(header)}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.map((row, index) => (
                          <tr key={index}>
                            {tableHeaders.map((header, idx) => (
                              <td key={idx}>{row[header]}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='Modal'
      >
        <Box
          className='Modal-box'
        >
          <h4
            className="Modal-box-heading"
          >
            Are you sure you want to close?
          </h4>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px",
              justifyContent: "flex-end", 
              gap:'3px'
            }}
          >
            <button
              onClick={() => handleConfirmClose(true)}
              className="btn btn-primary"
            >
              Yes
            </button>
            <button
              onClick={handleNoButtonClick}
              className="btn btn-primary"
            >
              No
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ExploreTable;
