import React, { useState, useEffect } from 'react';
import "./details.css"; 
import { headers,student_journey_api } from '../../../../assets/config';

const StudentJourney = ({userDetails}) => {
  const [documents, setDocuments] = useState([]);

  function getBrowserAndDevice(userAgentString) {
    const ua = userAgentString || navigator.userAgent;
    const browsers = {
        chrome: /(?:Chrome|CriOS)\/([\d.]+)/,
        edge: /Edge\/([\d.]+)/,
        safari: /Version\/([\d.]+)(?:.*Safari\/([\d.]+))?/,
        firefox: /Firefox\/([\d.]+)/,
        ie: /(?:MSIE |Trident\/.*; rv:)([\d.]+)/
    };
 
    let browserName = 'Unknown';
    Object.keys(browsers).some(key => {
        const match = ua.match(browsers[key]);
        if (match) {
            browserName = key;
            return true;
        }
    });
 
    const devices = {
        iphone: /iPhone/i,
        ipad: /iPad/i,
        ipod: /iPod/i,
        android: /Android/i,
        mac: /Macintosh/i,
        windows: /Windows/i
    };
 
    let deviceName = 'Unknown';
    Object.keys(devices).some(key => {
        if (ua.match(devices[key])) {
            deviceName = key;
            return true;
        }
    });
 
    return  browserName+ '/'+ deviceName ;
}
  const fetchDocuments = async () => {
    try {
      const response = await fetch(student_journey_api+userDetails.crn_id, {
        headers: headers
      });      
      const data = await response.json();
      setDocuments(data);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const renderTableRows = () => {
    if (documents.length === 0) {
      return (
          <p style={{textAlign:'center', fontSize:'20px' ,backgroundColor:"transparent"}}>Data not available</p>
      );
    }
    return documents.map((document, index) => (
      <tr key={index} style={{backgroundColor:"transparent"}}>
        {Object.entries(document).map(([key,value], index) => (  
          <td key={index} style={{backgroundColor:"transparent",color:'black'}}> { key==='User Agent'?getBrowserAndDevice(value): key==='Type'?<span style={{ color: value === 's' ? '#0cf50c' : value=='e'? 'red':'',fontSize:'40px'}}>&#x25cf;</span>:value}</td>
        ))}
      </tr>
    ));
  };

  return (
    <>
      
      <div className="card journey_card table-wrapper" style={{minHeight:'40vh'}}>
        <div className="card-body">
          
            <table className="table" style={{backgroundColor:"transparent"}}>
              {documents.length > 0 &&
                <thead  >
                  <tr >
                    {Object.keys(documents[0]).map((key, index) => (
                      <th key={index} style={{color:"black",backgroundColor:"transparent"}}>{key}</th>
                    ))}
                  </tr>
                </thead>
              }
              <tbody>
                {renderTableRows()}
              </tbody>
            </table>
          </div>
        </div>

    </>
  );
};

export default StudentJourney;
