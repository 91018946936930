import React from "react";
import "./details.css" 
import { studentFields } from "../../../../assets/config";

const StudentDetails = ({ userDetails }) => { 
  const studentDetailfiltered = {};
    studentFields.forEach((field) => {
      if (userDetails.hasOwnProperty(field)) {
        studentDetailfiltered[field] = userDetails[field];
      }
    });
    //  console.log('filtered',studentDetailfiltered);
  const length = Object.keys(studentDetailfiltered).length;
  const renderDetails = () => {
       
    return Object.entries(studentDetailfiltered).map(([key, value], index) => (
      <tr className="thtd" key={key}>
        <th className="thead" >{key}</th>
        <td className="tddetail" >
          {
            value === true ? "True" : value === false ? "False" : value
          }
        </td>
      </tr>
    ));
  };

  return (
    <div>
     <div className="title"><h2>Student Details</h2></div>
      <div className="card" >
        <div className="card-body student_details_table">
          <div className="tableleft">
            <table >{renderDetails().slice(0, length/2)}</table>
          </div>
          <div className="tableright">
            <table>{renderDetails().slice(length/2)}</table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;
