import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/css/demo.css";
import "./assets/css/frontend.css"
import "./assets/css/bootstrap.min.css";
import "./assets/vendor/css/pages/page-account-settings.css";
import "./assets/vendor/css/pages/page-auth.css";
import "./assets/vendor/css/pages/page-icons.css";
import "./assets/vendor/css/pages/page-misc.css";
import "./assets/vendor/css/core.css";
import "./assets/vendor/css/theme-default.css";
import "./assets/vendor/fonts/boxicons.css";
import "./assets/vendor/libs/apex-charts/apex-charts.css";
import "./assets/vendor/libs/highlight/highlight-github.css";
import "./assets/vendor/libs/highlight/highlight.css";
import "./assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css";
import "./js/bootstrap";
import "./js/helpers";
import "./js/menu";
import MainRouter from './router/MainRouter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <MainRouter/>
    </Router>
);

reportWebVitals();
