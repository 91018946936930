import {React,useEffect,useState} from "react";
import StudentDetails from "./StudentDetails";
import StudentJourney from "./StudentJourney";
import Sales from "./Sales";
import "./details.css"
import Review from "./Review"; 
import { health_check_api,headers,productId } from "../../../../assets/config";

const Details = ({ userDetails,grades, programs, exams, boards ,products }) => { 
  return (
    <div className="details" >
        <div className="details_and_journey">
            <div className="student_details">
                <StudentDetails userDetails={userDetails} />
            </div>
            <div className="review">
                   <Review userDetails={userDetails} grades={grades} programs={programs} exams={exams} boards={boards} products={products} />
            </div>
            <div className="student_journey">
                <StudentJourney userDetails={userDetails} />
            </div>
        </div>
            <div className="sales" >
                <Sales userDetails={userDetails} />
            </div>  
    </div>
 );
};

export default Details;
