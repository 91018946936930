import "./details.css";
import React, { useState, useEffect } from "react";
import image from '../../../../assets/img/icons/brands/freshdesk.png';


const Sales = ({userDetails,healthResult}) => {
  // const { messages } = healthResult;
  return (
    <>
     <div className="title"><h2>Fresh Desk</h2></div>
      <div className="card sales_card">
        <div className="card-body " style={{display:"flex",flexDirection:'column',justifyContent:"center"}}> 
        <div>
        <img src={image}  style={{width:'400px'}} alt="Fresh desk image" />
      </div>
          
        </div>
      </div>
     
    </>
  );
};

export default Sales;
