import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon, InputLabel, OutlinedInput, Checkbox, ListItemText, Snackbar,Alert } from "@mui/material";
import { IconButton, FormControl, FormControlLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from '@mui/material/Button';
import Switch from "@mui/material/Switch";
import { update_profile_api, headers } from "../../../../assets/config";
import { red } from "@mui/material/colors";
import { ThreeDots } from 'react-loader-spinner'

const FixItForm = ({
  userDetails,
  setShowModal,
  setFixIt,
  formData,
  setFormData,
  healthResult,
  grades,
  programs,
  boards,
  exams,
  products,
  refreshTrigger,
}) => {
  // const exams = ["JEE", "CUET", "NEET"];
  // const boards=["state","CBSE","ICSE"];

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  //   const bools = { true: true, false: false };
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const handleCloseModal = () => {
    // setFormData({ ...formData, exam_id:userDetails.exam_id  });
    // setFormData({ ...formData, product_id: "" });
    // setFormData({ ...formData, grade_id: userDetails.grade_id });
    setFixIt(false);
  };
  useEffect(() => {
    //console.log("Success Message:", successMessage);
  }, [successMessage]);

  useEffect(() => {
    //console.log("Error Message:", errorMessage);
  }, [errorMessage]);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleGradeChange = (event) => {
    setFormData({ ...formData, grade_id: event.target.value });
  };
  const handleBoardChange = (event) => {
    setFormData({ ...formData, board: event.target.value });
  };
  const handleProductChange = (event) => {
    setFormData({ ...formData, product_id: event.target.value });
  };
  const handleisActiveChange = (event) => {
    setFormData({ ...formData, is_active: !formData.is_active });
  };
  const handleProfileCompleteChange = (event) => {
    setFormData({
      ...formData,
      is_profile_complete: !formData.is_profile_complete,
    });
  };

  const handleProgramChange = (event) => {
    setFormData({ ...formData, program: event.target.value });
  };
  const handleExamChange = (event) => {
    setFormData({ ...formData, exam_id: parseInt(event.target.value) });
  };
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault(); 
    const payload ={}
    for (const key in formData) {
      if (formData[key] && formData[key] !== '') {
          payload[key] = formData[key];
      }
  }
    const requestOptions = {
      method: "PUT",
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify(payload),
    };

    try {
      if(requestOptions.body && !healthResult.program_check &&  formData.program_name==="") 
      throw new Error("Please Fill Program Name");

      const data = await fetch(update_profile_api, requestOptions);
      const json = await data.json();
      // console
      if (json.admission_number) { 
        setErrorMessage("");
        setSuccessMessage("Form submitted successfully!");
        refreshTrigger();
        setOpenSnackBar(true);
        // setFixIt(false);
        setLoading(false);
        
      } else {
        setLoading(false);
        if (json.detail) {
          setErrorMessage(json.detail);
          
        } else {
          setErrorMessage("Please ensure that all fields are correct");
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log(error, "ttttttttttttttt");
      if (Array.isArray(error.detail)) {
        setErrorMessage("*All Fields are required");
      } else {
        setErrorMessage(error.message);
      }
    }

    // fetch(update_profile_api, requestOptions)
    //   .then(response => {
    //     // if (!response.ok) {
    //     //   throw new Error('Network response was not ok');
    //     // }
    //     return response.json();
    //   })
    //   .then(data => {
    //     if (data.success) {
    //       setSuccessMessage("Form submitted successfully!");
    //     } else {
    //         if (Array.isArray(data.detail)) {
    //           setErrorMessage("*All Fields are required");

    //         } else {
    //             setErrorMessage(data.message);
    //         }
    //     //   //console.log(data.detail., "ooooo")
    //     }
    //   })
    //   .catch(error => {
    //     //console.error('Error:', error);
    //     setErrorMessage("An error occurred while submitting the form.");
    //     //console.log(errorMessage);
    //   })
    //   .finally(() => {

    //     // handleCloseModal();
    //   });

    // setFixIt(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "30px",
          paddingBottom: "0",
        }}
      >
        <h3>Fix It</h3>
        <IconButton onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </div>

      <form
        onSubmit={handleSubmit}
        className="Modal-box"
        style={{
          marginTop: "20px",
          width: "400px",
          padding: "7px",
          gap: "10px",
        }}
      >
        { (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Grade</InputLabel>
            <Select
              MenuProps={{
                PaperProps: { sx: { maxHeight: 220, macWidth: 170 } },
              }}
              labelId="grade-label"
              name="grade"
              value={formData.grade_id}
              onChange={handleGradeChange}
              label="Grade"
              id="demo-simple-selct"
            >
              {Object.entries(grades).map(([gradeId, gradeName]) => (
                <MenuItem key={gradeId} value={gradeId}>
                  {gradeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div
          style={{
            border: "1px solid #ddd",
            padding: " 10px",
            borderRadius: "4px",
          }}
        >
          <FormControlLabel
            style={{ display: "flex", justifyContent: "space-between" }}
            control={
              <Switch
                checked={formData.is_active}
                onChange={handleisActiveChange}
                name="isActive"
              />
            }
            label="Is active"
            labelPlacement="start"
          ></FormControlLabel>
        </div>
        <div
          style={{
            border: "1px solid #ddd",
            padding: " 10px",
            borderRadius: "4px",
          }}
        >
          <FormControlLabel 
            disabled
            style={{ display: "flex", justifyContent: "space-between" }}
            control={
              <Switch
                checked={formData.is_profile_complete}
                onChange={handleProfileCompleteChange}
                name="isProfileComplete"
              />
            }
            label="Profile Complete"
            labelPlacement="start"
          ></FormControlLabel>
        </div>
        {/* <FormControl>
          <InputLabel id="exams-label">Exams</InputLabel>
          <Select
            labelId="exams-label"
            id="exams-select"
            label="Exams"
            multiple
            value={formData.exams}
            onChange={handleExamChange}
            MenuProps={{
              PaperProps: { sx: { maxHeight: 220, macWidth: 170 } },
            }}
            fullWidth
            input={<OutlinedInput label="Exams" />}
            renderValue={(selected) =>
              selected.map((value) => exams[value]).join(", ")
            }
          >
            {Object.entries(exams).map(([examId, examName]) => (
              <MenuItem key={examId} value={examId}>
                <Checkbox checked={formData.exams.indexOf(examId) > -1} />
                {examName}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        {
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Exam</InputLabel>
            <Select
              MenuProps={{
                PaperProps: { sx: { maxHeight: 220, macWidth: 170 } },
              }}
              labelId="exam-label"
              name="exam"
              value={formData.exam_id}
              onChange={handleExamChange}
              label="board"
              id="demo-simple-selct"
            >
              {/* {boards.map((board) => (
              <MenuItem key={board} value={board}>
                <ListItemText primary={board} />
              </MenuItem>
            ))} */}
              {Object.entries(exams).map(([examId, examName]) => (
                <MenuItem key={examId} value={examId}>
                  {examName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        {/* {
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Board</InputLabel>
            <Select
              MenuProps={{
                PaperProps: { sx: { maxHeight: 220, macWidth: 170 } },
              }}
              labelId="board-label"
              name="board"
              value={formData.board}
              onChange={handleBoardChange}
              label="board"
              id="demo-simple-selct"
            >
               {boards.map((board) => (
              <MenuItem key={board} value={board}>
                <ListItemText primary={board} />
              </MenuItem>
            ))} 
               {Object.entries(boards).map(([boardId, boardName]) => (
                <MenuItem key={boardId} value={boardId}>
                  {boardName}
                </MenuItem>
              ))}
            </Select>
          </FormControl> 
        } */}
        {
          //   <FormControl fullWidth>
          //     <InputLabel id="demo-simple-select-label">Program</InputLabel>
          //     <Select
          //       MenuProps={{
          //         PaperProps: { sx: { maxHeight: 220, macWidth: 170 } },
          //       }}
          //       labelId="program-label"
          //       name="program"
          //       value={formData.program}
          //       onChange={handleProgramChange}
          //       label="program"
          //       id="demo-simple-selct"
          //     >
          //       {Object.entries(programs).map(([programId, programName]) => (
          //         <MenuItem key={programId} value={programId}>
          //           {programName}
          //         </MenuItem>
          //       ))}
          //     </Select>
          //   </FormControl>
          <TextField
            name="program_name"
            label="Program Name"
            value={formData.program_name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        }
        {
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Product</InputLabel>
            <Select
              MenuProps={{
                PaperProps: { sx: { maxHeight: 220, macWidth: 170 } },
              }}
              labelId="product-label"
              name="product"
              value={formData.product_id}
              onChange={handleProductChange}
              label="product"
              id="demo-simple-selct"
            >
              {Object.entries(products).map(([product_id, product_name]) => (
                <MenuItem key={product_id} value={product_id}>
                  {product_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        {errorMessage && (
          <div style={{ color: "red", fontSize: "14px", fontWeight: "500" }}>
            {errorMessage}
          </div>
        )}
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          {/* <Button variant="contained" type="submit">
            Submit
          </Button> */}
          <Button size="large" sx={{minWidth: '120px', height: '45px'}} variant="contained" type="submit">{loading ? (
            <ThreeDots
            visible={true}
            height="30px"
            width="30px"
            color="#fff"
            radius="40px"
            ariaLabel="three-dots-loading"
            />
          ) : "Submit"}</Button>
        </div>
      </form>

      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        action={action}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FixItForm;
