import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation} from "react-router-dom";

//importing css
// import "../assets/vendor/bootstrap/css/bootstrap.min.css";
// import "../assets/vendor/bootstrap-icons/bootstrap-icons.css";
// import "../assets/css/style.css";

import { ROUTES } from "../pages/constants/routes";
import LoginForm from "../pages/components/Tables/Explore/LoginForm";
import ResetPassword from "../pages/components/validations/ResetPassword";
import ExploreTable from "../pages/components/Tables/Explore/Exploretable";
import SessionTimeoutWrapper from "../utils/SessionTimeout";
import Footer from "../pages/components/validations/Footer";

const AuthenticatedRoutes = () => {
  const location = useLocation();

  const shouldShowFooter = () => {
      const nonFooterRoutes = [ROUTES.LOGIN, ROUTES.RESET_PASSWORD];
      return !nonFooterRoutes.includes(location.pathname);
  };

  return (
    <>
        <Switch>
          <Route exact path={ROUTES.LOGIN} component={LoginForm} />
          <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          <Route path={ROUTES.EXPLORE_TABLE} component={ExploreTable} />  
          <Redirect to={ROUTES.LOGIN} />
        </Switch>
      {shouldShowFooter() && <Footer />}
    </>
  );
};

const MainRouter = () => {
  return (
      <Router>
          <Switch>
              <Route path={ROUTES.LOGIN} component={LoginForm} />
              <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
              {/* Add other routes as needed */}
              <Route>
                  <SessionTimeoutWrapper>
                      <AuthenticatedRoutes />
                  </SessionTimeoutWrapper>
              </Route>
              <Route path={ROUTES.EXPLORE_TABLE} component={ExploreTable} />
              <Redirect to={ROUTES.LOGIN} />
          </Switch>
      </Router>
  );
};

export default MainRouter;