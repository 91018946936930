export const tableHeaders = [
  "Name",
  //'phone',
  "Grade",
  "Admission Number",
  // 'reg source',
  "Registered On",
  // 'Onboarded',
  "Last Active At",
  // 'Score Attempt',
  // 'Olympiad Attempt',
];

export const studentFields = [
  "Name",
  "phone",
  "email",
  "admission_number",
  "crn_id",
  "grade_id",
  "hall_ticket_id",
  "is_profile_complete",
  "is_active",
  "last_active_at",
];

export const CLICK_TO_CALL_API = "https://call-management.infinitylearn.com/connect-call";
//   CLICK_TO_CALL_API = "https://staging.call-management.devinfinitylearn.in/connect-call"
export const CLICK_TO_CALL_API_AUTH_TOKEN = "QN4JWTW1GW3VCIA9";
export const ProductName = "meta";
export const title = ["Insights"];
export const productId = 200;
export const productName = "meta";

export const grades_api = "https://gatewayapimeta.infinitylearn.com/api/v1/grade?productId=" + productId;
export const program_api="https://gatewayapimeta.infinitylearn.com/api/v1/program?productId=1&page=1&limit=100"
export const boards_api="https://gatewayapimeta.infinitylearn.com/api/v1/board"
export const exam_api="https://gatewayapimeta.infinitylearn.com/api/v1/exam?page=1&limit=1000"
export const health_check_api ="https://gatewayapimeta.infinitylearn.com/api/v1/user/insights";
export const products_api = "https://gatewayapimeta.infinitylearn.com/api/v1/product?page=1&limit=100";
export const update_profile_api ="https://gatewayapimeta.infinitylearn.com/api/v1/user/meta/update"; 
export const student_journey_api ="https://gatewayapimeta.infinitylearn.com/api/v1/gateway-consumer/student-journey/"
export const agents_login_api='https://gatewayapicms.infinitylearn.com/api/v1/user/agents/login'


export const roles_with_edit_access=[4,34]
export const headers = {
  "tenant-id": 2,
  "product-id": productId,
  platform: "web",
  type: "application/json",
};

export const fields = [
  {
    name: "grade",
    type: "singleSelect",
    label: "Grade",
    options: [],
    action: grades_api,
  },
  {
    name: "program",
    type: "singleSelect",
    label: "Program",
    options: [],
  },
  {
    name: "exam",
    type: "singleSelect",
    label: "Exam",
    options: [],
  },
  {
    name: "schools",
    type: "singleSelect",
    label: "School",
    options: [],
  },
  {
    name: "campus",
    type: "singleSelect",
    label: "Campus",
    options: [],
  },
];

// export const moduleConfig = {
//   loginusers: [
//       {
//           name: "admission_number",
//           type: "text",
//           label: "Admission Id",
//           schema: nameSchema,
//           mandatory: true,
//       },
//       {
//           name: "password",
//           type: "password",
//           label: "Password",
//           schema: passwordSchema,
//           mandatory: true,
//       },
//   ],

//   tenants: {
//       actions: {
//           create: createTenant,
//           update: updateTenant,
//       },
//       fields: [
//           {
//               name: "tenant_id",
//               type: "text",
//               label: "Tenant Id",
//               mandatory: true,
//               showInCreate: false,
//               editable: false,
//           },
//           {
//               name: "products",
//               type: "searchmultiSelect",
//               label: "Select Products",
//               options: [],
//               mandatory: true,
//               showInCreate: true,
//               schema: multiSelectSchema,
//               editable: true,
//           },
//           {
//               name: "tenant_name",
//               mandatory: true,
//               type: "text",
//               label: "Tenant Name",
//               schema: nameSpaceSchema,
//               maxChar: 50,
//               editable: true,
//               showInCreate: true,
//           },
//           {
//               name: "tenant_code",
//               mandatory: true,
//               type: "text",
//               label: "Tenant Code",
//               button: "Generate Code",
//               refField: "tenant_name",
//               schema: codeSchema,
//               maxChar: 5,
//               editable: true,
//               showInCreate: true,
//           },
//           {
//               name: "image",
//               type: "file",
//               label: "Image",
//               editable: false,
//               showInCreate: true,
//           },
//           {
//               name: "primary_contact_name",
//               mandatory: true,
//               type: "text",
//               label: "Contact Name",
//               schema: nameSpaceSchema,
//               maxChar: 50,
//               editable: true,
//               showInCreate: true,
//           },
//           {
//               name: "primary_contact_phone",
//               mandatory: true,
//               type: "phone",
//               label: "Contact Phone",
//               schema: phoneSchema,
//               maxChar: 10,
//               editable: true,
//               showInCreate: true,
//           },
//           {
//               name: "primary_contact_email",
//               mandatory: true,
//               type: "email",
//               label: "Contact Email",
//               schema: emailSchema,
//               editable: true,
//               showInCreate: true,
//           },
//           {
//               name: "grades",
//               mandatory: true,
//               type: "searchmultiSelect",
//               label: "Select Grades",
//               options: [],
//               schema: multiSelectSchema,
//               editable: true,
//               showInCreate: true,
//           },
//           // {
//           //     name: "source",
//           //     mandatory: false,
//           //     type: "text",
//           //     label: "Source",
//           //     showInCreate: false,
//           //     editable: true,
//           // },
//           {
//               name: "source",
//               type: "searchsingleSelect",
//               label: "Select Source",
//               schema: singleselectSchema,
//               mandatory: true,
//               options: [
//                   { label: "Infinity Meta", value: "IM" },
//                   { label: "Infinity Learn", value: "IL" },
//               ],
//               showInCreate: true,
//               editable: false,
//           },
//       ],
//   },
// }