import "./details.css";
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { TextField } from "@mui/material";
import FixItForm from "./FixIt";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { health_check_api,headers,productId,roles_with_edit_access } from "../../../../assets/config";

import {
  CLICK_TO_CALL_API,
  CLICK_TO_CALL_API_AUTH_TOKEN,
  ProductName,
} from "../../../../assets/config.js";

const Review = ({
  userDetails,
  grades,
  programs,
  exams,
  boards, 
  products
}) => {
  const [isCallButtonEnabled, setIsCallButtonEnabled] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // console.log("userDetails sales ", userDetails);
  const [showModal, setShowModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const [fixIt, setFixIt] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openIssues, setOpenIssues] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [healthResult, setHealthResult] =useState({});
  const [showIssues,setShowIssues] = useState(false); 
  const [hasAccess, setHasAccess] = useState(false);
  // console.log("show health", showIssues);
  const [formData, setFormData] = useState({
    admission_id: userDetails.admission_number,
    // admission_id: '213270002',
    
    is_active: userDetails.is_active,
    // is_profile_complete:
    //   userDetails.is_profile_complete !== undefined
    //     ? userDetails.is_profile_complete
    //     : false,
    grade_id: userDetails.grade_id || "",
    exam_id: userDetails.exam_id, 
    tenant_id: 2,
    program_name: "",
    // board: "",
    product_id: userDetails.product_id
  });

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    if (inputPhoneNumber.length <= 10) {
      setPhoneNumber(inputPhoneNumber);
      setIsCallButtonEnabled(inputPhoneNumber.length === 10);
    }
  };
  useEffect(() => {

    let hasAccess = roles_with_edit_access.some(element => JSON.parse(localStorage.getItem('user_data')).roles.includes(element)); 
    setHasAccess(hasAccess); 
    // console.log(hasAccess,"has access");
    const fetchHealth = async () => {
      try {
        const response = await fetch(health_check_api, { 
        method:"POST", 
          headers: {...headers,'Content-Type':"application/json"},  
        
          body: JSON.stringify({
            crn_id: userDetails.crn_id,
            // crn_id: 'CRNP200T00002HHGE2II',

            product_id: productId
          })
        });
        if (!response.ok) {
          throw new Error('Failed to fetch grades');
        }
        const data = await response.json(); 
        setHealthResult(data);  
        setFormData({...formData,is_profile_complete:data.is_profile_complete});
        setShowIssues(data.health_status==='failed')
        // console.log(data,'health data');          
        } catch (error) {
        // console.error('Error fetching health:', error);
      }
    };

    fetchHealth();
    setFormData({
      ...formData,
      // admission_id: userDetails.admission_number, 
      admission_id: '213270002', 
      is_active: userDetails.is_active,
      grade_id: userDetails.grade_id || "",
      exam_id: 1,
      program_name: "",
      // board: "",
      product_id: userDetails.product_id
    }); 


  }, [refreshTrigger]);

  const handleFixItPopUp = () => {
    setFixIt(true);
  };
  const handleIssuesPopUp = () => {
    setOpenIssues(true);
  };

  const handleCallNowClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPhoneNumber(""); // Clear the phone number input
    setFixIt(false);
  };
  const handleRefreshTrigger = () => { 
    setRefreshTrigger(!refreshTrigger);
  };
  const handleCallButtonClick = () => {
    // Logic to handle call functionality here
    setOpenSnackbar(true);
    // console.log("Calling number:", phoneNumber);
    // handleCloseModal(); // Close the modal after the call button is clicked
    fetch(CLICK_TO_CALL_API, {
      method: "POST",
      body: JSON.stringify({
        callerPhoneNumber: phoneNumber,
        crnId: userDetails.crn_id,
        productName: ProductName,
      }),
      headers: {
        "Content-Type": "application/json",
        "X-Secret-Token": CLICK_TO_CALL_API_AUTH_TOKEN,
      },
    })
      .then((response) => response.json())
      .then((resp) => {
        if (resp["Status"] === "success") {
          setSnackbarMessage("Calling.. successfully!");
        } else {
          setSnackbarMessage("Call failed. Please try again.");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
        setSnackbarMessage("Failed to call. Please try again later.");
      });
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseSnackbar}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
     <div className="title"><h2 >Student Journey</h2></div>
      {/* <div className="title">Student Journey</div> */}
      <div>
        <button className="btn btn-primary" onClick={handleCallNowClick}>
           Call Now
        </button>
        { showIssues &&
          <button
            className="btn btn-primary"
            style={{ marginLeft: "10px" }}
            onClick={handleIssuesPopUp}
          >
            Show Issues
          </button>
        }
        {showIssues && hasAccess && (
          <button
            className="btn btn-primary"
            style={{ marginLeft: "10px" }}
            onClick={handleFixItPopUp}
          >
            Fix it
          </button>
        )}
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="Modal"
        >
          <Box className="Modal-box">
            <h4 className="Modal-box-heading">Call the student </h4>
            <div
              className="Modal-box-heading"
              style={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={selectedCountryCode}
                sx={{ width: "60px" }}
              />
              <TextField
                label="Enter Your Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                inputProps={{ maxLength: 10 }}
                // onChange={(e)=>setPhoneNumber(e.target.value)}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                justifyContent: "center",
                gap: "3px",
              }}
            >
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={handleCallButtonClick}
                disabled={!isCallButtonEnabled}
              >
                Call
              </Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={fixIt}
          onClose={() => {setFixIt(false) }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="Modal"
        >
          <Box className="Modal-box">
            <FixItForm
              userDetails={userDetails}
              setFixIt={setFixIt}
              handleCloseModal={handleCloseModal}
              formData={formData}
              setFormData={setFormData}
              healthStatus={healthResult}
              grades={grades}
              programs={programs}
              exams={exams}
              boards={boards}
              healthResult={healthResult} 
              products={products}
              refreshTrigger ={handleRefreshTrigger}
            />
          </Box>
        </Modal>
        <Modal
          open={openIssues}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              width: "30%",
              height: "30%",
              backgroundColor: "#fff",
              color: "black",
            }}
          >
            <IconButton
              sx={{ position: "absolute", top: 5, right: 5 }}
              onClick={() => {
                setOpenIssues(false);
              }}
            >
              <CloseIcon />
            </IconButton>
            <h2>Data issues</h2>
            <ul>
              {healthResult.messages &&
                healthResult.messages.length > 0 &&
                healthResult.messages.map((issue, index) => (
                  <li key={index}>{issue}</li>
                ))}
            </ul>
          </Box>
        </Modal>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage }
          action={action}
        />
      </div>
    </>
  );
};

export default Review;
